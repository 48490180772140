.cards-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

.cards-compared {
  border-radius: 20px;
  width: 450px;
  border: 1px solid rgba(0, 0, 0, 0.25)
}

.cards-compared-image {
  width: 280px;
  height: 427px;
  margin: 10px auto 0 auto;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
}

.cards-compared-content {
  max-width: 400px;
  height: fit-content;
  border-radius: 0 0 20px 20px;
  background-color: white;
}

.cards-compared-content h3 {
  margin: 22px 0 16px 24px;
  font-family: gotham;
  font-size: 22px;
  font-weight: bold;
  color: #707070;
}

.cards-compared-content h4 {
  margin: 0 0 16px 24px;
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.cards-compared-content ul {
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.ingredientComp{
  font-size: 14px;
  color: #707070;
  margin-left:24px;
  font-weight: bold;
}

.richTextStyleComp {
  display: block;
  position: relative;
  font-family: book;
  font-size: 14px;
  color: #707070;
  margin-left:24px;
  padding-bottom: 10px;
  overflow-wrap: break-word;
}

.richTextStyleComp p span{
  color: #707070 !important; 
  font-size: 14px !important;
}

.stepTwo-NoProduct{
  margin: 1.25rem 0;
  font-family: book;
}

.dinamicCharStyleExportation {
  display: inline;
  width: 400px;
  position: relative;
  margin-top: 0px;
}

.flagNormalizer {
  display: inline;
  max-width: 20px;
  max-height: 20px;
  margin-left: 3px;
  vertical-align: bottom;
}

.characteristics{
  width: 90%;
  /* margin: 1rem 0; */
  margin: 0 0 16px 24px;
}

.characteristics ul {
  list-style-type: none;
  padding-left: 0;
}

div .ant-collapse-header{
  font-weight: bold;
  font-size: 16px;
}

div .ant-collapse-content-box li,
div .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #4b4b4b;
}

.characteristicPosition > * {
  padding: 0;
  margin: 0;
  margin-top: 2px;
  min-width: 400px;
  min-height: 20px;
  display: block;
}