.titleProductDetail {
  font-family: gotham;
  font-weight: bold;
  font-size: 45px;
  text-align: left;
  margin-top: 1%;
  margin-bottom: -1%;
  margin-left: 0;
  margin-right: 0;
}

.richTextStyle {
  display: block;
  position: relative;
  font-family: book;
  font-size: 18px;
  color: #505050;
  margin-top: 0%;
}

.richTextStylePdf {
  display: block;
  position: relative;
  font-family: book;
  font-size: 14px;
  color: #505050;
  margin-top: 0%;
}

.codeProductDetail {
  font-family: book;
  font-size: 18px;
  text-align: left;
  color: #323232;
  margin-top: 0;
  margin-bottom: 30px;
}

.caractProductTitle {
  font-family: gotham;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}

.ingredientProductTitle {
  font-family: gotham;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
}

.ingredientProductTitlePdf {
  font-family: gotham;
  font-weight: bold;
  font-size: 18px;
  margin-bottom: 0;
}

.productDescription {
  font-family: book;
  font-size: 18px;
  color: #505050;
  margin-top: 0;
}

.characteristics{
  width: 60%;
  margin: 1rem 0;
}

.characteristics ul {
  list-style-type: none;
  padding-left: 0;
}

div .ant-collapse-header{
  font-weight: bold;
  font-size: 16px;
}

div .ant-collapse-content-box li,
div .ant-collapse > .ant-collapse-item > .ant-collapse-header {
  color: #4b4b4b;
}

.characteristicPosition > * {
  padding: 0;
  margin: 0;
  margin-top: 2px;
  min-width: 400px;
  min-height: 20px;
  display: block;
}

.dinamicCharStyleExportation {
  display: inline;
  width: 400px;
  position: relative;
  margin-top: 0px;
}

.exportationStyle {
  display: inline;
  min-width: 40px;
  min-height: 25px;
  position: relative;
  margin-top: 0;
  margin-left: 0;
  vertical-align: top;
}

.flagNormalizer {
  display: inline;
  max-width: 20px;
  max-height: 20px;
  margin-left: 3px;
  vertical-align: bottom;
}

.footContact {
  position: relative;
  margin-top: 2%;
  display: block;
}

.share span {
  font-family: bold;
  font-size: 14px;
  color: #4b4b4b;
}
.shareMail {
  display: inline;
  position: relative;
  top: 5px;
  left: 10px;
}

.shareMail svg {
  height: 18px;
  width: 18px;
  padding: 0;
  margin: 0;
}

.shareMail a {
  color: gray;
}

.shareMail img {
  width: 18px;
}

.shareWhatsapp {
  display: inline;
  position: relative;
  top: 5px;
  left: 25px;
}

.shareWhatsapp img {
  width: 18px;
}

.video {
  margin-top: 0px;
  position: relative;
  min-height: 500px;
}

.video iframe {
  margin-top: 0px;
  margin-left: 0.5%;
  position: relative;
  width: 900px;
  height: 530px;
}

.btnExport {
  position: relative;
  left: 60%;
  bottom: 25px;
}

.btnExport,
.btnExport:active,
.btnExport:visited .btnExport:link .btnExport:focus {
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #4b4b4b;
  border-radius: 16px;
  width: 150px;
  height: 32px;
  background-color: white;
  color: #4b4b4b;
}

.btnExport:focus {
  border-radius: 16px;
  outline: 0;
}

.btnStandard,
.btnStandard:active,
.btnStandard:visited .btnStandard:link .btnStandard:focus {
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #4b4b4b;
  border-radius: 16px;
  width: 150px;
  height: 32px;
  background-color: white;
  color: #4b4b4b;
}

.btnStandard:focus {
  border-radius: 16px;
  outline: 0;
}