.btnDown {
  position: absolute;
  left: 75px;
  border: 1px solid #70707080;
  border-radius: 5px;
  top: 540px;
  left: 105px;
  width: 40px;
  height: 26px;
  text-align: center;
  cursor: pointer;
}

.btnDown #down{
    margin-top: 3px;
}

.btnUp {
    position: absolute;
    border: 1px solid #70707080;
    border-radius: 5px;
    left: 105px;
    width: 40px;
    height: 26px;
    top: -40px;
    text-align: center;
    cursor: pointer;
  }