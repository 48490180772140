.filter{
    padding-left: 30px;
}

.filter ul{
    list-style-type:none;
    padding-left: 0;
}
.filter span{
    color: #939393;
}

.filter h3{
    font-family: black;
    font-size: 18px;
    color: #323232;
}

.filter h4{
    font-family: black;
    font-size: 14px;
    margin-block-end: -10px;
    margin-block-start: -4px;
}

.filter li{
    font-family: book;
    font-size: 13px;
    color: #323232;
}

.filter a{
    color: #323232;
}
