#print{
  padding: 0;
  margin: 1rem 0;
}

.pdfCards-flex{
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-content: center;
}

#print .pdfCards-compared {
  width: 450px;
  margin: 0 1rem;
  border: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 20px;
}

.pdfCards-compared-image {
  width: 280px;
  height: 427px;
  margin: 10px auto 0 auto;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
}

.pdfCards-compared-content {
  max-width: 400px;
  height: fit-content;
  border-radius: 0 0 20px 20px;
  background-color: white;
}

.pdfCards-compared-content h3 {
  margin: 22px 0 16px 24px;
  font-family: gotham;
  font-size: 22px;
  font-weight: bold;
  color: #707070;
}

.pdfCards-compared-content h4 {
  margin: 0 0 16px 24px;
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.pdfCards-compared-content ul {
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.characteristicTilte-comparePdf{
  margin: 16px 0 8px 0 !important;
  font-weight: bold;
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.characteristicName-comparePdf{
  font-weight: bold;
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.characteristicAttribute-comparePdf{
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.pdfIngredientComp{
  font-size: 14px;
  color: #707070;
  margin-left:24px;
  font-weight: bold;
}

.pdfRichTextStyleComp {
  display: block;
  position: relative;
  font-family: book;
  font-size: 14px;
  color: #707070;
  margin-left:24px;
  padding-bottom: 10px;
  overflow-wrap: break-word;
}

.pdfRichTextStyleComp p span{
  color: #707070 !important; 
  font-size: 14px !important;
}

.pdfNutritionFactCardComp {
  width: 100%;
  height: fit-content;
}

.pdfNutritionFactTableComp {
  position: relative;
  margin: 1rem auto 0 auto;
  border: 1px solid #707070;
  border-radius: 10px 10px 0 0;
  width: 341px;
  height: fit-content;
}

.pdfNutritionFactTableTextComp {
  width: 341px;
  position: relative;
  padding: 5px;
  margin: 0 auto 1rem auto;
  border: 1px solid #707070;
  border-radius: 0 0 10px 10px;
  line-height: 18px;
}

.pdfNutritionFactTableTextComp span {
  color: #707070;
  font-family: book;
  font-size: 10px;
  text-align: left;
}

.pdfNutritionFactTableComp table{
  border: none;
}

.pdfNutritionFactTableComp th {
  font-family: book;
  font-size: 12px;
  letter-spacing: 0.72px;
  color: #333333;
  text-align: left;
  padding-top: 20px;
  padding-left: 5px;
}

.pdfNutritionFactTableComp td {
  font-family: book;
  font-size: 10px;
  letter-spacing: 0.72px;
  color: #505050;
  padding-bottom: 15px;
}

.pdfNutritionFactTableComp tr {
  width: 99%;
}

.pdfNutritionFactTableComp tbody {
  height: fit-content;
  display: block;
}

.pdfNutritionFactTableComp tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

.pdfNutritionFactTableComp tbody::-webkit-scrollbar:vertical {
  width: 11px;
}


.pdfNutritionFactTableComp tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

.pdfNutritionFactTableComp
thead,
tbody tr {
  display: table;
  width: 95%;
  table-layout: fixed;
}

.pdfNutritionFactTableComp
tbody td {
  padding-bottom: 20px;
}

.pdfNutritionFactTableComp
thead {
  padding-left: 5px;
}

.pdfNutritionFactTableComp
table,
td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-collapse: collapse;
  padding-left: 5px;
}
.pdfNutritionFactTableComp
table,
td tr:last-child td {
  border-bottom: 1px solid #ddd;
}

.firstLevelIdentation{
  padding-left: 5px;
}

.secondLevelIdentation {
  padding-left: 20px;
}

.thirdLevelIdentation {
  padding-left: 40px;
}