header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 70px;
  background: #009639 0% 0% no-repeat padding-box;
  width: 100%;
}

.header-logo {
  position: relative;
  top: 6px;
  left: 10px;
}

.header-search * {
  fill: white;
}

.header-search input {
  background: #26a657;
  font-family: book;
  font-size: 16px;
  color: white;
  border-color: #26a657;
}

.header-search :hover {
  border-color: #26a657;
}

.header-search :active {
  border-color: #26a657;
  color: white;
}

.header-search :focus {
  background: #26a657;
  border-color: #26a657;
}

.header-comparison {
  position: relative;
  top: 10px;
}

.navbar-comparison-link {
  display: inline-block;
  position: relative;
  padding-bottom: 3px;
  color: white;
  font-weight: bold;
  font-family: book;
}

.navbar-comparison-link:after {
  content: "";
  display: block;
  margin: auto;
  height: 3px;
  width: 10%;
  background: white;
  transition: width 0.5s ease, background-color 0.5s ease;
}

.navbar-comparison-link:hover:after {
  width: 100%;
  background: white;
}