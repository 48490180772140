div .ant-steps{
  padding: 0 25vw;
}

.steps-content {
  min-height: 200px;
  margin-top: 1rem;
  padding: 1rem;
  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
  padding-bottom: 24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.last-step-action{
  max-width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

[data-theme='dark'] .steps-content {
  margin-top: 16px;
  border: 1px dashed #303030;
  background-color: rgba(255, 255, 255, 0.04);
  color: rgba(255, 255, 255, 0.65);
  padding-top: 80px;
}
.ant-steps-item-process > .ant-steps-item-container > .ant-steps-item-icon {
  background-color: #26A657;
}
.ant-steps-item-process .ant-steps-item-icon {
  background-color: #fff;
  border-color: #26A657;
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title::after{
  background-color: #26A657;
}

.ant-steps-item-finish .ant-steps-item-icon{
  border-color: #26A657;
}
.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon{
  color: #26A657;
}
.ant-btn-primary{
  background: #26A657;
  border-color: #26A657;
}
.ant-btn-primary:hover{
  background: #59ce86;
  border-color: #26A657;
}

.ant-btn-primary:focus, .ant-btn-primary::after{
  background: #26A657;
  border-color: #26A657;
}
.ant-btn-round:hover{
  border-color: #26A657;
  color: #26A657;
}

.compareWarning{
  text-align: right;
  font-size: 12px;
  color: #ff0000;
  margin: 0;
  padding: .5rem 0 0 0;
}

.btnExportComp,
.btnExportComp:active,
.btnExportComp:visited .btnExportComp:link .btnExportComp:focus {
  font-weight: bold;
  font-size: 11px;
  border: 2px solid #4b4b4b;
  border-radius: 16px;
  width: 150px;
  height: 32px;
  background-color: white;
  color: #4b4b4b;
  margin-left: 2rem;
}

.btnExportComp:focus {
  border-radius: 16px;
  outline: 0;
}