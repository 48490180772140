.landing-image {
  background-image: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("../../assets/images/placa_opc6.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 100%;
  min-width: 1024px;
  width: 100%;
  height: auto;
  position: fixed;
  top: 0;
  left: 0;
}

.landing-logo {
  position: absolute;
  top: 44px;
  left: 35px;
}

.landing-text {
  width: 100%;
  text-align: center;
  position: fixed;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: white;
}

.landing-text h1 {
  font-family: gotham;
  font-size: 100px;
  font-weight: bold;
  color: white;
  margin: 0;
}

.landing-text button {
  font-family: gotham;
  font-weight: bold;
  width: 240px;
  height: 70px;
}

.landing-text button:hover {
  color: #fff;
  background-color: #009639;
  border-color: #009639;
  cursor: pointer;
}

.landing-text a {
  text-decoration: none;
}

.borderLogin {
  border-radius: 10px;
  background: #e0e0e059;
  width: 400px;
  height: 80%;
}

.borderLogin h3 {
  font-family: bold;
  color: #252525;
  font-size: 20px;
  padding: 1.5rem;
}

.borderLogin label {
  font-family: book;
  color: #747474;
  font-size: 14px;
  font-weight: bold;
  padding: 0 1rem;
}

.formInput{
  margin-bottom: 32px;
}

.formInputPass{
  margin-bottom: 16px;
}

/* .formButton{
  margin-bottom: 10px;
} */

._spectrum-Textfield-input_61339{
  margin: 0 1rem;
}

.borderLogin #btnSectionAuth {
  margin: auto;
}
._spectrum-Button--primary_7a745{
  margin-bottom: 1rem;
}
._spectrum-ActionButton-label_7a745, ._spectrum-Button-label_7a745{
  font-size: 25px;
}

.borderLogin .errorMessageAuth {
  font-family: book;
  color: red;
  font-size: 14px;
  padding-bottom: 10px;
}

.eye-icon{
  position: relative;
  bottom: 27px;
  left: 350px;
}

.eye-icon svg {
  color: #26A657;
}

@media (max-width: 1080px) {
  .landing-logo {
    top: 25px;
  }
  .landing-text {
    top: 50%;
  }
  .landing-text h1 {
    font-size: 60px;
  }
  .borderLogin {
    width: 350px;
    height: 200px;
  }
  .borderLogin h3 {
    font-size: 1rem;
    padding: .5rem;
  }
  .borderLogin label {
    font-size: 10px;
  }
  ._spectrum-Textfield-input_61339{
    height: 20px;
  }
  .landing-text button {
    width: 160px;
    height: 40px;
  }
  ._spectrum-ActionButton-label_7a745, ._spectrum-Button-label_7a745{
    font-size: 1rem;
  }
  .formInput, .formButton{
    margin-bottom: 10px;
  }
}

/* @media screen and (max-width: 992px) and (max-height: 500px) {
  div .landing-text {
    position: none;
    top: 0;
    left: 0;
    transform: translate(0%, 0%);
    margin-top: 4rem;
  }
  .landing-text h1{
    font-size: 60px;
    margin-bottom: 0;
  }
  .borderLogin {
    height: 225px;
    padding-top: 1rem;
  }
  .formInput{
    height: 40px;
  }
  .formInput, .formButton{
    margin-bottom: 10px;
  }
  .eye-icon{
    position: relative;
    bottom: 42px;
    left: 310px;
  }
} */