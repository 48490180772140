.header-search div {
  width: 30vw;
  height: 32px;
  z-index: 2;
}

#searchField svg {
  fill: #ffffff;
  z-index: 2;
}
[dir=ltr] ._spectrum-Textfield-icon_61339 {
    left: 20px;
}

.suggestions {
  list-style: none;
  margin-top: 0;
  max-height: 100px;
  overflow-y: auto;
  padding-left: 0;
  width: 30vw;
  border: 1px solid #707070;
  background: #ffffff 0% 0% no-repeat padding-box;
  z-index: 2;
  position: absolute;
}

.suggestions li {
  padding: 0.5rem;
  z-index: 2;
}

.suggestion-active, .suggestions li:hover {
  z-index: 2;
  background-color: #cccccc;
  cursor: pointer;
  font-weight: 700;
  color: #353535;
}