.slick-arrow {
  width: 45px;
  height: 65px;
  background-color: white;
  background: rgba(229, 229, 229, 0.8);
  border-radius: 5px;
  cursor: pointer;
}

.slick-prev svg{
    position: relative;
    right: 15px;
    bottom: 2px;
}

.slick-prev{
    top: 45%;
    position: absolute;
    left: 20px;
    z-index: 99;
}

.slick-next{
    top: 45%;
    position: absolute;
    right: 60px;
    z-index: 99;
}

.slick-next svg{
    position: relative;
    right:  12px;
    bottom: 2px;
}

.slick-disabled{
    background: rgba(25, 25, 25, .1);
    visibility: hidden;
}

a{
    text-decoration: none;
}