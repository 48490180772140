.titleProductDetailPdf {
    padding-top: 70px;
    font-family: gotham;
    font-weight: bold;
    font-size: 45px;
    text-align: left;
    margin-top: 10px;
    margin-bottom: -10px;
    color: #323232;
    max-width: 500px;
}

#print{
  padding:35px;
}

.contentNormalizer{
  position:relative;
  margin-left:50px;
}

.spaceOptimizer {
  position: relative;
  min-height: 100px;
}

.codeProductDetailPdf {
  font-family: book;
  font-size: 18px;
  text-align: left;
  color: #323232;
  margin-top: 0;
  margin-bottom: 30px;
}

.imagePdf {
  border-radius: 10px;
  max-width: 500px;
  max-height: 750px;
}

.caractProductTitlePdf {
  font-family: gotham;
  font-weight: bold;
  font-size: 22px;
  margin-bottom: 0;
  position:relative;
  margin-top:30px;
}

.productDescriptionPdf {
  font-family: book;
  font-size: 18px;
  color: #505050;
  margin-top: 0;
}

.characteristicsPdf ul {
  padding-left: 0;
  max-width: 450px;
}

.characteristicTitlePdf{
  font-family: gotham;
  font-weight: bold;
  font-size: 18px;
  color: #4b4b4b;
  text-decoration: underline;
}

.characteristicPositionPdf{
  padding: 0;
  margin: 0 0 5px 0;
}

.characteristicPosition{
  padding-left: 5px;
  margin-bottom: 5px;
}

.characteristicNamePdf {
  font-family: gotham;
  font-weight: bold;
  font-size: 16px;
  color: #505050;
}

.characteristicAttributePdf {
  font-family: book;
  font-size: 16px;
  color: #505050;
}

.nutritionFactTablePdf {
  width: 725px;
}

.nutritionFactTablePdf table {
  border: none;
}

.nutritionFactTablePdf th {
  font-family: bold;
  font-size: 14px;
  color: #333333;
  text-align: left;
  padding-top: 10px;
  padding-bottom: 5px;
  padding-left: 6px;
}

.nutritionFactTablePdf td {
  font-family: book;
  font-size: 14px;
  color: #505050;
  padding-bottom: 10px;
}

.nutritionFactTablePdf tr {
  width: 98%;
}

.nutritionFactTablePdf thead, tbody tr {
  display: table;
  width: 98%;
  table-layout: fixed;
}

.nutritionFactTablePdf tbody tr {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
}

.nutritionFactTablePdf tbody tr:first-child td {
  border-top: 1px solid #ddd;
}

.nutritionFactTablePdf tbody td {
  padding-bottom: 10px;
  border: none;
}

.nutritionFactTablePdf thead {
  padding-left: 5px;
}

.nutritionFactTablePdf table, tr, td {
  border-collapse: collapse;
  padding-left: 5px;
  padding-top: 10px;
}

.nutritionFactTextPdf {
  padding: 5px;
  padding-left: 10px;
  width: 695px;
  margin-top: 40px;
  border: 1px solid #d3d3d3;
  border-radius: 4px;
}

.nutritionFactTextPdf span {
  color: #252525;
  font-family: book;
  font-size: 14px;
  text-align: left;
}

.export {
  margin-left: 30px;
}  