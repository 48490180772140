.image-gallery-slide img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 453px;
  height: 453px;
  overflow: hidden;
  object-position: center center;
  border-radius: 10px;

  max-width: 100%;
  max-height: 100%;
}
.image-gallery{
  width: 90%;
}



.image-gallery-slide
.image-gallery-image {
    object-fit: fit;
    margin-left: 0;
    width: 800px;
    height: 400px;
}
.image-gallery-thumbnail-inner .image-gallery-thumbnail-image {
    height: 134px;
    width: 190px;
    object-fit: contain;
}

.image-gallery-thumbnails-wrapper.left .image-gallery-thumbnails,
.image-gallery-thumbnails-wrapper.right .image-gallery-thumbnails {
    width: 220px;
    object-fit: contain;
}

.image-gallery-thumbnail {
    opacity: 0.4;
    object-fit: contain;
}

.image-gallery-thumbnail,
.image-gallery-thumbnail.active,
.image-gallery-thumbnail:hover,
.image-gallery-thumbnail:focus {
  border: none;
  cursor: pointer;
  margin-bottom: 15px;
}

.image-gallery-thumbnail-image {
    border-radius: 10px;
    margin-left: 15%;
    object-fit: contain;
}

.image-gallery-thumbnail.active {
  opacity: 1;
}
