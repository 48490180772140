.productsRelated h2 {
  font-family: bold;
  font-size: 22px;
  color: #323232;
}

.prev, .next{
    position: relative;
    font-family: book;
    font-size: 15px;
    color: #747474;
    margin-left: 15px;
}

.prev {
    top: -160px;
    padding-top: 5px;
}
.next {
  padding-top: 15px;
  top: -160px;
  
}

.current {
  position: relative;
  font-family: bold;
  font-size: 15px;
  margin-top: 25px;
  margin-left: 15px;
  margin-bottom: 10px;
  top: -160px;
}

.prev span,
.next span{
  cursor: pointer;
}


.box {
  position: relative;
  top: 58px;
  width: 2px;
  height: 44px;
  background: #323232 0% 0% no-repeat padding-box;
  border-radius: 1px;
  z-index: 1;
}

.box2 {
  position: relative;
  top: -30px;
  width: 2px;
  height: 132px;
  background: #eaeaea 0% 0% no-repeat padding-box;
  border-radius: 1px;
}

.showMoreProductsRelated{ 
    cursor: pointer;
    font-family: book;
    font-size: 15px;
    color: #747474;
    position: relative;
    top: -120px;
    left: 20px
}