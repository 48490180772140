.footer{
  background-color: #009639;
  width: 100%;
  position: relative;
}

.footer-container{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.footer-brand{
  text-align: left;
  font-family: book;
  color: #fff;
  font-size: 14px;
  padding: 1.5rem 0 0 1rem;
  margin: 0;
}

.footer-copyright {
  text-align: right;
  font-family: book;
  color: #fff;
  font-size: 14px;
  padding: .5rem 0;
  padding: 1rem 1rem 0 0;
  margin: 0;
}

.footer-version{
  text-align: right;
  font-family: book;
  color: #fff;
  font-size: 10px;
  padding: 0.25rem 1rem 1rem 0;
  margin: 0;
}

@media screen and (max-width: 576px) {
  .footer-brand, .footer-copyright{
    font-size: 10px;
  }
  .footer-version{
    font-size: 8px;
  }
}