body {
  background-color: white;
}

#content {
  background-color: white;
}

@font-face {
  font-family: "black";
  src: local("black"), url(./assets/fonts/Black.otf) format("opentype");
}

@font-face {
  font-family: "Gotham";
  src: local("Gotham"), url(./assets/fonts/Bold.otf) format("opentype");
}

@font-face {
  font-family: "book";
  src: local("book"), url(./assets/fonts/Book.otf) format("opentype");
}

@font-face {
  font-family: "bold";
  src: local("bold"), url(./assets/fonts/ExtraBold.otf) format("opentype");
}

@media all and (min-width: 1440px) {
  body {
    width: 1424px;
    margin: 0 auto;
  }
}

