.card-compare {
  border-radius: 20px;
  border: 1px solid rgba(0, 0, 0, 0.25)
}

.card-compare-image {
  width: 280px;
  height: 427px;
  background-repeat: no-repeat, repeat;
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  margin-top: 10px;
}

.card-compare-content {
  width: 279px;
  height: 180px;
  border-radius: 0 0 20px 20px;
  background-color: white;
}

.card-compare-content h3 {
  margin-left: 24px;
  font-family: gotham;
  font-size: 22px;
  font-weight: bold;
  color: #2c2c2c;
}

.card-compare-content h4 {
  margin-left: 24px;
  margin-top: -10px;
  font-family: book;
  font-size: 14px;
  color: #707070;
}

.card-compare h2 {
  text-align: center;
  position: relative;
  top: 80%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: rgba(25, 25, 25, 0.2);
  width: 200px;
  height: 80px;
  border-radius: 15px;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 35px;
  letter-spacing: 0px;
  padding-top: 20px;
  vertical-align: middle;
}
