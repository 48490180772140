.nutritionFactCardComp {
  width: 100%;
  height: 400px;
}

.nutritionFactTableComp {
  position: relative;
  margin: 1rem auto 0 auto;
  border: 1px solid #707070;
  border-radius: 10px 10px 0 0;
  width: 341px;
  height: 350px;
}

.nutritionFactTableTextComp {
  width: 341px;
  position: relative;
  padding: 5px;
  margin: 0 auto 1rem auto;
  border: 1px solid #707070;
  border-radius: 0 0 10px 10px;
  line-height: 18px;
}

.nutritionFactTableTextComp span {
  color: #707070;
  font-family: book;
  font-size: 10px;
  text-align: left;
}

.nutritionFactTableComp table{
  border: none;
}

.nutritionFactTableComp th {
  font-family: book;
  font-size: 12px;
  letter-spacing: 0.72px;
  color: #333333;
  text-align: left;
  padding-top: 20px;
  padding-left: 5px;
}

.nutritionFactTableComp td {
  font-family: book;
  font-size: 10px;
  letter-spacing: 0.72px;
  color: #505050;
  padding-bottom: 15px;
}

.nutritionFactTableComp tr {
  width: 99%;
}

.nutritionFactTableComp tbody {
  overflow: auto;
  height: 280px;
  display: block;
}

.nutritionFactTableComp tbody::-webkit-scrollbar {
  -webkit-appearance: none;
}

.nutritionFactTableComp tbody::-webkit-scrollbar:vertical {
  width: 11px;
}


.nutritionFactTableComp tbody::-webkit-scrollbar-thumb {
  border-radius: 8px;
  border: 2px solid white;
  background-color: rgba(0, 0, 0, .5);
}

.nutritionFactTableComp
thead,
tbody tr {
  display: table;
  width: 95%;
  table-layout: fixed;
}

.nutritionFactTableComp
tbody td {
  padding-bottom: 20px;
}

.nutritionFactTableComp
thead {
  padding-left: 5px;
}

.nutritionFactTableComp
table,
td {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-collapse: collapse;
  padding-left: 5px;
}
.nutritionFactTableComp
table,
td tr:last-child td {
  border-bottom: 1px solid #ddd;
}

.firstLevelIdentation{
  padding-left: 5px;
}

.secondLevelIdentation {
  padding-left: 20px;
}

.thirdLevelIdentation {
  padding-left: 40px;
}
