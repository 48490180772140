.notFoundImage{
    display: inline-block;
}

.textNotFound{
    display: inline-block;
    top: 263px;
    position: relative;
}

.textNotFound h2{
    font-family: gotham;
    font-size: 30px;
    color: #323232;
    margin-bottom: 0;
}

.textNotFound span{
    font-family: book;
    font-size: 22px;
    color: #323232;
}

.textNotFound a{
    text-decoration: underline;
}